.main_container {
  display: flex;
  flex: 1;
  padding: 20px 15px;
  flex-direction: column;
  overflow-y: auto;
  height: max-content;
}

.header {
  font-size: 24px;
  display: flex;
  justify-content: space-between;
}

.left_header > span {
  font-weight: 600;
  font-size: 1.75rem;
  background: -webkit-linear-gradient(#1976d2, #b1d4e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.left_header > div {
  color: #46474f;
  font-size: 20px;
  font-weight: 400;
}

.stats_container {
  display: flex;
  flex: 1;
  padding-top: 20px;
}

.donations_stats_wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.donations_stats_left {
  display: flex;
  flex: 1;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #e4e4e4;
}

.donations_stats_right {
  display: flex;
  height: 100%;
  flex: 0.5;
}

.donations_stats_right > div {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.donations_stats_right_inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  color: #1a393c;
}

.table_container {
  display: flex;
  flex: 1;
  height: 900px;
  margin-top: 10px;
  border: 1px solid #e4e4e4;
  padding: 10px;
  border-radius: 10px;
  flex-direction: column;
}

.table_container_header {
  display: flex;
  font-size: 24px;
  justify-content: space-between;
}

.table_container_header > div {
  display: flex;
  flex-direction: row;
}

.table_container_header > div > sl-input {
  width: 400px;
}

.table_container_header > div > sl-dropdown {
  margin-left: 10px;
}

.table_container_body {
  display: flex;
  flex: 1;
  height: 100%;
  margin-top: 20px;
  flex-direction: column;
}
