sl-input::part(form-control-label)::after,
sl-checkbox::part(label)::after,
sl-select::part(form-control-label)::after,
sl-switch::part(label)::after,
sl-textarea::part(form-control-label)::after {
  color: red;
}

sl-button {
  cursor: pointer;
}

:root {
  /* Primary */
  --sl-color-primary-50: hsl(33.33, 81.82%, 93.53%);
  --sl-color-primary-100: hsl(33, 80%, 90.2%);
  --sl-color-primary-200: hsl(32.83, 79.1%, 86.86%);
  --sl-color-primary-300: hsl(34.15, 76.47%, 83.33%);
  --sl-color-primary-400: hsl(33.85, 76.47%, 80%);
  --sl-color-primary-500: hsl(35.68, 70.7%, 69.22%);
  --sl-color-primary-600: hsl(37, 68%, 62%);
  --sl-color-primary-700: hsl(38.1, 52.07%, 47.45%);
  --sl-color-primary-800: hsl(39.51, 72.78%, 33.14%);
  --sl-color-primary-900: hsl(41.43, 98.44%, 25.1%);
  --sl-color-primary-950: hsl(40, 100%, 21.18%);
}
