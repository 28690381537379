.main_container {
    display: flex;
    flex: 1;
    padding: 20px 15px;
    flex-direction: column;
    overflow-y: auto;
    height: max-content;
  }
  
  .breadcrumb {
    display: flex;
    background-color: white;
    padding: 15px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .header {
    font-size: 24px;
    display: flex;
    justify-content: space-between;
  }
  
  .left_header > span {
    background: -webkit-linear-gradient(#f3cd86, #905037);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .left_header > div {
    color: gray;
    font-size: 14px;
    font-weight: 400;
  }
  
  .stats_container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  
  .stats_container > div {
    display: flex;
    flex: 1;
  }
  
  .summary_stats_container {
    display: flex;
    flex: 1;
    align-items: center;
  }
  
  .summary_stats_container > div {
    display: flex;
    flex: 1;
    background-color: white;
    border-radius: 10px;
    margin: 20px;
    border: 1px solid lightgray;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  
  .icon_summary {
    font-size: 30px;
    color: #b67d12;
    border-radius: 50%;
    background-color: #f3cd86;
    padding: 15px;
  }
  
  .summary_stats_container > div > div {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  
  .summary_stats_container > div > div > span {
    font-size: 20px;
  }
  
  .summary_stats_container > div > div > label {
    font-size: 12px;
    color: gray;
  }
  
  .charts_wrapper {
    margin-top: 10px;
    max-height: 300px;
  }
  
  .bar_chart_wrapper {
    display: flex;
    flex: 1;
    border: 1px solid lightgray;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    flex-direction: column;
    overflow: hidden;
  }
  
  .show_more {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-end;
    position: sticky;
    bottom: -10px;
    z-index: 30;
    width: calc(100% + 18px);
    height: 40px;
    margin: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    transition: 0.5s;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.6167060574229692) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  
  .show_more:hover,
  .show_more:active {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-end;
    position: sticky;
    bottom: -10px;
    z-index: 30;
    width: calc(100% + 18px);
    height: 40px;
    margin: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.6167060574229692) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    color: #b67d12;
    transition: 0.5s;
  }
  
  .bar_chart_header {
    display: flex;
    width: 100%;
    font-size: 20px;
    color: #1a393c;
  }
  
  .bar_chart_data_wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  
  .bar_chart_title {
    display: flex;
    justify-content: space-between;
    color: #1a393c;
  }
  
  .bar_chart_title > label {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-end;
  }
  
  .bar_chart_progress {
    display: flex;
    border-radius: 50px;
    height: 20px;
    background-color: #f3cd8640;
    margin-bottom: 15px;
  }
  
  .bar_chart_progress > div {
    background: rgb(182, 125, 18);
    background: linear-gradient(
      270deg,
      rgba(182, 125, 18, 1) 0%,
      rgba(243, 205, 134, 1) 100%
    );
    border-radius: 50px;
  }
  
  .pie_chart_wrapper {
    display: flex;
    flex: 0.5;
    background-color: white;
    border-radius: 10px;
    border: 1px solid lightgray;
    margin-left: 20px;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }
  
  .table_container {
    display: flex;
    flex: 1;
    height: 900px;
    margin-top: 10px;
    border: 1px solid #e4e4e4;
    padding: 10px;
    border-radius: 10px;
    flex-direction: column;
  }
  
  .table_container_header {
    display: flex;
    font-size: 24px;
    justify-content: space-between;
  }
  
  .table_container_header > div {
    display: flex;
    flex-direction: row;
    margin-right:10px
  }
  
  .table_container_header > div > sl-input {
    width: 400px;
  }
  
  .table_container_header > div > sl-dropdown {
    margin-left: 10px;
  }
  
  .table_container_body {
    display: flex;
    flex: 1;
    height: 100%;
    margin-top: 20px;
    flex-direction: column;
  }
  