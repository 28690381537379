.main_container{
    display: flex;
    flex: 1;
    height: max-content;
}

.middlepanel_container{
    display: flex;
    flex: 1;
    height: auto;
    min-height: 100vh;
    flex-direction: column;
}

.drawer::part(base){
    width: 350px;
}

.drawer::part(title){
    display: none;
}

.drawer::part(header){
    margin-bottom: 0;
    margin-top: 5px;
    justify-content: flex-end;
}

.drawer::part(body){
    padding: 0;
}