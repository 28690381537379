.main_container {
  display: flex;
  flex: 1;
  padding: 20px 15px;
  flex-direction: column;
  overflow-y: auto;
  height: max-content;
}

.inner_container {
  display: flex;
  background-color: white;
  border-radius: 10px;
  border: 1px solid lightgray;
  flex-direction: column;
  padding: 20px;
}

.picture_select_container {
  display: flex;
  flex-direction: column;
}

.custom_file_upload > input {
  display: none;
}

.custom_file_upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafbfc;
  border: 2px dashed #4c535f;
  border-radius: 18px;
  width: 120px;
  height: 120px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  color: #4c535f;
  transition: 0.5;
  margin-top: 10px;
}

.custom_file_upload > sl-icon {
  font-size: 40px;
  color: #4c535f;
}

.custom_file_upload:hover {
  background-color: #eaeef2;
  transition: 0.5;
}

.custom_file_upload:active {
  background-color: #dae2e9;
  transition: 0.5;
}

.inputs_container {
  display: flex;
  flex-direction: column;
}

.inputs_container sl-select,
.inputs_container sl-input {
  width: 100%;
}

.inputs_container > div {
  margin-top: 20px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.footer > sl-button::part(label) {
  color: black;
}

.notif_options_container {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.notif_descriptions {
  display: flex;
  flex-direction: column;
  flex: 0.6;
}

.notif_descriptions > div:nth-child(2) {
  color: gray;
  font-size: 14px;
}

.notif_buttons {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.notif_buttons > div {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
}
