.main_container{
    display: flex;
    flex: 1;
    padding: 20px 15px;
    flex-direction: column;
    overflow-y: auto;
    height: max-content;
}

.user_details_container{
    display: flex;
    flex: 1;
    background-color: white;
    border-radius: 10px;
    border: 1px solid lightgray;
    margin-top: 10px;
    flex-direction: column;
    padding: 20px;
}

.user_info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.user_info span {
    font-weight: bolder;
}

.user_permissions_container{
    margin-top: 20px;
}

sl-details::part(header){
    background-color: #FBF0D9;
}

sl-details::part(content){
    display: flex;
}

.switch_categories_container{
    display: flex;
    flex: 1;
    flex-direction: column;
}

.switch_categories_container > div {
    margin-bottom: 10px;
}

.switch_categories_title{
    font-size: 20px;
    font-weight: bolder;
}