@tailwind base;
@tailwind components;
@tailwind utilities;

.container-white {
  display: flex;
  flex: 1;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.container-white-no-padding {
  display: flex;
  flex: 1;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
}
.container-main {
  display: flex;
  flex: 1;
  padding: 20px 15px;
  flex-direction: column;
}

.page-title {
  font-size: x-large;
  font-weight: 600;
}
