.main_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 15px;
}

.graph_container {
  display: flex;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 20px;
}

.campaign_buttons {
  display: flex;
  padding: 20px;
  padding-right: 0;
  font-size: 24px;
  justify-content: space-between;
}

.campaign_details {
  display: flex;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  flex-direction: column;
  font-size: 16px;
}

.campaign_details > div {
  margin-top: 20px;
}

.campaign_details > div > span {
  color: gray;
}

sl-icon {
  font-size: 20px;
}

sl-button {
  margin-left: 10px;
}

.add_cash_button::part(base) {
  background-color: #e0ad5b;
  transition: 0.5s;
}

.add_cash_button::part(base):hover {
  background-color: #e6bd7b;
  transition: 0.5s;
}

.add_cash_button::part(base):active {
  background-color: #efd6ad;
  transition: 0.5s;
}

.add_to_archive_button::part(base) {
  background-color: #f5adad;
  transition: 0.5s;
}

.add_to_archive_button::part(base):hover {
  background-color: #f7bdbd;
  transition: 0.5s;
}

.add_to_archive_button::part(base):active {
  background-color: #f9cdcd;
  transition: 0.5s;
}

.edit_campaign_button::part(base) {
  background-color: #1a393c;
  transition: 0.5s;
}

.edit_campaign_button::part(base):hover {
  background-color: #476062;
  transition: 0.5s;
}

.edit_campaign_button::part(base):active {
  background-color: #75888a;
  transition: 0.5s;
}

.delete_campaign_button::part(base) {
  border: 1px solid #1a393c;
  background-color: transparent;
  transition: 0.5s;
}

.delete_campaign_button::part(base):hover {
  background-color: #e8ebeb;
  transition: 0.5s;
}

.delete_campaign_button::part(base):active {
  background-color: #bac3c4;
  transition: 0.5s;
}

.add_cash_button sl-icon,
.add_cash_button::part(label),
.delete_campaign_button sl-icon,
.delete_campaign_button::part(label),
.add_to_archive_button::part(label) {
  color: #1a393c;
}
.edit_campaign_button sl-icon,
.edit_campaign_button::part(label) {
  color: white;
}

.campaign_details_header {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.campaign_details_header > sl-switch::part(label) {
  font-size: 16px;
}

.campaign_description > p {
  margin-top: 5px;
  font-size: 14px;
  color: gray;
  word-wrap: break-word;
  width: 100%;
  word-break: break-all;
}

.campaign_extra_options {
  display: flex;
}

.campaign_extra_options > div {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.campaign_extra_options > div > div {
  margin-top: 10px;
  font-size: 20px;
}

.table_container_body {
  display: flex;
  flex: 1;
}

sl-tab-group::part(base) {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

sl-tab::part(base) {
  display: flex;
  flex-direction: column;
}
