.main_container {
  display: flex;
  flex: 1;
  padding: 20px 15px;
  flex-direction: column;
  padding-bottom: 20px;
}

.details_container {
  display: flex;
  flex: 1;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  flex-direction: column;
}

.title {
  font-weight: 600;
  font-size: 20px;
}

.small_title {
  margin-top: 15px;
  font-weight: 600;
  font-size: 16px;
}

.inputs_container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.inputs_container sl-select,
.inputs_container sl-input {
  width: 100%;
}

.inputs_container > div {
  margin-top: 20px;
}

.amount_presets_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 20px;
  flex-wrap: wrap;
}

.amount_presets_container > div {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.amount_presets_container > div > div {
  border: 2px solid #1976d2;
  width: 123px;
  height: 110px;
  border-radius: 10px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  transition: 0.5s;
  margin-top: 10px;
}

.kiosk_preset {
  display: flex;
  flex-direction: column !important;
}

.kiosk_preset > div,
.kiosk_preset > sl-icon-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.preset_amount {
  margin-top: -30px;
}
