.main_container {
  display: flex;
  flex: 1;
  min-height: 100vh;
  max-height: max-content;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/Kiosk-Background.png");
}

.left_panel {
  display: flex;
  flex: 0.8;
  background-color: #1a393c;
  justify-content: center;
  align-items: center;
}

.left_panel > img {
  display: flex;
  object-fit: contain;
  width: 535px;
  height: 380px;
}

.right_panel {
  display: flex;
  flex: 1;
  margin: 50px;
  flex-direction: column;
}

.title_container {
  display: flex;
  flex-direction: column;
}

.title_container > h1 {
  font-size: 28px;
  font-weight: 800;
}

.title_container > span {
  font-size: 14px;
  font-weight: 400;
  color: #94959b;
}

.body_container > sl-input {
  margin-top: 10px;
}

.footer {
  margin-top: 30px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.footer > a {
  font-size: 14px;
}

.button_container {
  display: flex;
  flex: 1;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.button_container > span {
  font-size: 14px;
  margin-top: 30px;
}

.button_container > span > a {
  color: #1a393c;
  font-weight: 600;
}

.otp_input_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.otp_input_container > input {
  width: 40px;
  border: none;
  border-bottom: 3px solid rgba(26, 57, 60, 0.5);
  margin: 0 10px;
  text-align: center;
  font-size: 36px;
  cursor: not-allowed;
  pointer-events: none;
}

.otp_input_container > input:focus {
  border-bottom: 3px solid orange;
  outline: none;
}

.otp_input_container > input:nth-child(1) {
  cursor: pointer;
  pointer-events: all;
}
