.main_container{
    display: flex;
    flex: 1;
    padding: 20px 15px;
    flex-direction: column;
}

.details_container{
    display: flex;
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
}

.title{
    font-weight: 600;
    font-size: 20px;
}

.small_title{
    margin-top: 15px;
    font-weight: 600;
    font-size: 16px;
}

.inputs_container{
    display: flex;
    flex: 1;
    flex-direction: column;
}

.inputs_container sl-select, .inputs_container sl-input{
    width: 100%;
}

.inputs_container > div{
    margin-top: 20px;
}

.amount_presets_container{
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 20px;
}

.amount_presets_container > div{
    display: flex;
    margin-top: 10px;
}

.amount_presets_container > div > div{
    border: 1px solid black;
    width: 123px;
    height: 110px;
    border-radius: 10px;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    transition: 0.5s;
}

.amount_presets_container > div > div:hover{
    background-color: #f5e6cd;
    transition: 0.5s;
}

.amount_presets_container > div > div:active{
    background-color: #E0AD5B;
    transition: 0.5s;
}

.amount_presets_container > div > div:first-child{
    margin-left: 0;
}

.footer_container{
    display: flex;
    margin-top: 20px;
    margin-left: auto;
}

.footer_container > sl-button::part(base){
    border: 0;
    background-color: #1A393C;
    font-size: 16px;
    color: white;
    transition: 0.5s;
}

.footer_container > sl-button::part(base):hover, .footer_container > sl-button::part(base):active{
    color: #F3CD86;
    transition: 0.5s;
}

.footer_container > sl-button::part(label):hover, .footer_container > sl-button::part(label):active{
    color: #F3CD86;
    transition: 0.5s;
}

sl-textarea{
    width: 100%;
}